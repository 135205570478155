import { amountToString, areAmountsEqual, zeroAmount } from '@orus.eu/amount'
import type { ProductAttributeDescription, ProductAttributeValue, ProductAttributes } from '@orus.eu/dimensions'
import { translate, type Language, type LocalizableMessage, type TranslationKey } from '@orus.eu/translations'
import { useMemo } from 'react'
import { useLanguage } from '../localization'

export type ProjectedProductAttributeDescription = ProductAttributeValue & {
  label: string
  tooltip: string | undefined
}

export type ProjectedProductAttributes = {
  productName: string
  attributes: ProjectedProductAttributeDescription[]
}

/**
 * Projects an array of attributes using a project function
 */
export function useProductAttributesProjection(
  attributes: ProductAttributeDescription[],
  projection: (
    attribute: ProductAttributeDescription,
    language: Language,
  ) => ProjectedProductAttributeDescription | undefined,
): ProjectedProductAttributeDescription[] {
  const language = useLanguage()
  return useMemo(() => projectProductAttributes(attributes, language, projection), [attributes, language, projection])
}

/**
 * Project several array of attributes grouped by product using a project function
 */
export function useProductsAttributesProjection(
  productsAttributes: Array<ProductAttributes>,
  projection: (
    attribute: ProductAttributeDescription,
    language: Language,
  ) => ProjectedProductAttributeDescription | undefined,
): Array<{ productName: string; attributes: Array<ProjectedProductAttributeDescription> }> {
  const language = useLanguage()
  return useMemo<Array<{ productName: string; attributes: ProjectedProductAttributeDescription[] }>>(
    () =>
      productsAttributes
        .map(({ productName, attributes }) => ({
          productName,
          attributes: projectProductAttributes(attributes, language, projection),
        }))
        .filter(({ attributes }) => attributes.length > 0),
    [productsAttributes, language, projection],
  )
}

function projectProductAttributes(
  attributes: ProductAttributeDescription[],
  language: Language,
  projection: (
    attribute: ProductAttributeDescription,
    language: Language,
  ) => ProjectedProductAttributeDescription | undefined,
): ProjectedProductAttributeDescription[] {
  return attributes.flatMap((attribute): Array<ProjectedProductAttributeDescription> => {
    const projectionOutput = projection(attribute, language)
    return projectionOutput ? [projectionOutput] : []
  })
}

export function projectAttributeInProductBlock(
  attribute: ProductAttributeDescription,
  language: Language,
): ProjectedProductAttributeDescription | undefined {
  return attribute.inProductContext
    ? {
        label: translateAttribute(attribute.inProductContext, language),
        tooltip: translateAttributeTooltip(attribute, language),
        ...extractProductAttributeValue(attribute),
      }
    : undefined
}

export function projectAttributeInQuoteDetailDialog(
  attribute: ProductAttributeDescription,
  language: Language,
): ProjectedProductAttributeDescription | undefined {
  return attribute.inQuoteDetailContext
    ? {
        label: translateAttribute(attribute.inQuoteDetailContext, language),
        tooltip: translateAttributeTooltip(attribute, language),
        ...extractProductAttributeValue(attribute),
      }
    : undefined
}

export function projectAttributeInSummaryUnderQuote(
  attribute: ProductAttributeDescription,
  language: Language,
): ProjectedProductAttributeDescription | undefined {
  return attribute.inGeneralSummary
    ? {
        label: translateAttribute(attribute.inGeneralSummary, language),
        tooltip: translateAttributeTooltip(attribute, language),
        ...extractProductAttributeValue(attribute),
      }
    : undefined
}

export function translateAttributeTooltip(
  value: { tooltip?: string } | { localizableTooltip?: LocalizableMessage<TranslationKey> },
  language: Language,
): string | undefined {
  return 'tooltip' in value
    ? value.tooltip
    : 'localizableTooltip' in value && value.localizableTooltip
      ? translate(value.localizableTooltip, language)
      : undefined
}

function translateAttribute(
  value: { label: string } | { localizableLabel: LocalizableMessage<TranslationKey> },
  language: Language,
): string {
  return 'label' in value ? value.label : translate(value.localizableLabel, language)
}

export function productAttributeValueToString(productAttribute: ProductAttributeValue, language: Language): string {
  switch (productAttribute.type) {
    case 'string':
      return productAttribute.value
    case 'included':
      return translate(productAttribute.value ? 'included' : 'not_included', language)
    case 'deductible':
      return areAmountsEqual(productAttribute.value, zeroAmount)
        ? 'Aucune'
        : amountToString(productAttribute.value, amountToStringOptions)
    case 'limit':
    case 'amount':
      return amountToString(productAttribute.value, amountToStringOptions)
  }
}

const amountToStringOptions = { addCurrency: true, displayDecimals: 'when-amount-has-cents' } as const

function extractProductAttributeValue(productAttribute: ProductAttributeDescription): ProductAttributeValue {
  const { type, value } = productAttribute
  return { type, value } as ProductAttributeValue
}
